import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "../components/NavBar";
import { Banner } from "../components/Banner";
import { Skills } from "../components/Skills";
import { Projects } from "../components/Projects";
import { Contact } from "../components/Contact";
import { Footer } from "../components/Footer";
import { Lets } from '../components/Lets';
import { MailchimpForm } from '../components/MailchimpForm';

function Main() {
  return (

    <div className="App">
      <NavBar />
      <Banner />
      
      <Lets />
      <Contact />
      <MailchimpForm />
      <Footer />
    </div> 
  );
}

export default Main
