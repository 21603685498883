import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import Lottie from "lottie-react";
import register from '../assets/img/register.json'
import load from '../assets/img/loading.json'

export const Creator_form = () => {
  const formInitialDetails = {
    name: '',
    email: '',
    phone: '',
    insta: '',
    category: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [loading, setLoading] = useState(false);
  const [instruct, setInstruct]= useState(false);

  const onFormUpdate = (category, value) => {
      setFormDetails({
        ...formDetails,
        [category]: value
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let response = await fetch("https://elevance-backend.onrender.com/creatorReg", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(formDetails),
    });
    let result = await response.json();
    if (result === 'success'){
      setInstruct(true);
      setLoading(false);
    }
     setFormDetails(formInitialDetails); 

      /*setStatus({ succes: true, message: `your follower's count is: ${result}`}); */

  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
                <div className={"animate__animated animate__zoomIn" }>
                  <Lottie animationData={register} loop />
              </div>
          </Col>
          <Col size={12} md={6}>
           {loading ? <div><Lottie animationData={load} loop /><h2>Submitting...</h2></div> : instruct ? <div><h1>Thank you registering. Please check your email for the Elevance Media Kit, which you have to use in all your content. CREATE AND EARN NOW!</h1></div> : 
           <TrackVisibility>
           {({ isVisible }) =>
             <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
             <h2>Creator Form</h2>
             <form onSubmit={handleSubmit}>
               <Row>
                 <Col size={12} sm={6} className="px-1">
                   <input type="text" value={formDetails.firstName} placeholder="Name" onChange={(e) => onFormUpdate('name', e.target.value)} />
                 </Col>
                 <Col size={12} sm={6} className="px-1">
                   <input type="email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} />
                 </Col>
                 <Col size={12} sm={6} className="px-1">
                   <input type="tel" value={formDetails.phone} placeholder="Whatsapp No." onChange={(e) => onFormUpdate('phone', e.target.value)}/>
                 </Col>
                 <Col size={12} sm={6} className="px-1">
                   <input type="text" value={formDetails.insta} placeholder="Instagram Username" onChange={(e) => onFormUpdate('insta', e.target.value)}/>
                 </Col>
                 <Col size={12} sm={6} className="px-1">
                 <select name="category" onChange={(e) => onFormUpdate('category', e.target.value)} className="category" required>
<option value="">Category</option>
<option value="sports">Sports</option>
<option value="gaming">Gaming</option>
<option value="regional">Regional</option>
<option value="music">Music</option>
<option value="music">Movies-TV Shows</option>
<option value="music">Infortainment</option>
<option value="music">Adventure</option>
<option value="music">Educational</option>
<option value="music">Literature</option>
<option value="music">Technology</option>
<option value="music">Vlogging</option>
<option value="music">Politics</option>
<option value="music">Fashion</option>
</select>
                 </Col>
                 <Col size={12} className="px-1">
                   
                   <button type="submit"><span>Submit</span></button>
                 </Col>
               </Row>
             </form>
           </div>}
         </TrackVisibility> }
          </Col>
        </Row>
      </Container>
    </section>
  )
}
