import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import brand from '../assets/img/brand.json'
import Lottie from "lottie-react";
import load from '../assets/img/loading.json'

export const Brand_form = () => {
  const formInitialDetails = {
    brand: '',
    email: '',
    phone: '',
    insta: '',
    targetAudience: '',
    budgetRange: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [loading, setLoading] = useState(false);
  const [instruct, setInstruct]= useState(false);

  const onFormUpdate = (category, value) => {
      setFormDetails({
        ...formDetails,
        [category]: value
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let response = await fetch("https://elevance-backend.onrender.com/brandReg", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(formDetails),
    });
    let result = await response.json();
    console.log(result);
    if (result === 'success'){
      setInstruct(true);
      setLoading(false);
    }
     setFormDetails(formInitialDetails); 

    setFormDetails(formInitialDetails); 
    

  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={"animate__animated animate__zoomIn" }>
                  <Lottie animationData={brand} loop />
              </div>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            {loading ? <div><Lottie animationData={load} loop /><h2>Submitting...</h2></div> : instruct ? <div><h2>Thank you registering. We will contact you back as soon as we can!!</h2></div> :
            <TrackVisibility>
            {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
              <h2>Brand Form</h2>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col size={12} sm={6} className="px-1">
                    <input type="text" value={formDetails.firstName} placeholder="Brand Name" onChange={(e) => onFormUpdate('brand', e.target.value)} />
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input type="email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} />
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input type="tel" value={formDetails.phone} placeholder="Contact No." onChange={(e) => onFormUpdate('phone', e.target.value)}/>
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input type="text" value={formDetails.insta} placeholder="Instagram ID" onChange={(e) => onFormUpdate('insta', e.target.value)}/>
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                  <select name="targetAudience" className="category" onChange={(e) => onFormUpdate('targetAudience', e.target.value)} required>
<option value="">Target Audience</option>
<option value="5000">5,000+</option>
<option value="10000">10,000+</option>
<option value="20000">20,000+</option>
<option value="50000">50,000+</option>
<option value="100000">100,000+</option>
</select>
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                  <select name="budgetRange" onChange={(e) => onFormUpdate('budgetRange', e.target.value)} className="category" required>
<option value="">Budget Range</option>
<option value="5000">{`< 5000 INR`}</option>
<option value="10000">10,000 INR </option>
<option value="15000">15,000 INR</option>
<option value="30000">30,000 INR</option>
<option value="50000">50,000 INR</option>
</select>
                  </Col>
                  
                  <p className="success">Prices are negotiable. Please write to us to know more about it!</p><br /><br />
                  <Col size={12} className="px-1">
                    
                    <button type="submit"><span>Submit</span></button>
                  </Col>
                </Row>
              </form>
            </div>}
          </TrackVisibility>
            }
            
          </Col>
        </Row>
      </Container>
    </section>
  )
}
