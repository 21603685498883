import React, { useState } from 'react'
import { Footer } from '../components/Footer'
import { NavBar } from '../components/NavBar'
import axios from 'axios';
import 'animate.css';
import { Creator_form } from '../components/Creator-Form';


function Creator() {
  const [username, setUsername] = useState('');
  const getfollowers =async ()=>{
    axios.post(`http://localhost:3001/check`, {username: username}).then((response) => {
      console.log(response);
    })
    await fetch(`http://localhost:3001/check`, {
      method: "POST",
      headers: {
        'User-Agent' : 'Instagram 76.0.0.15.395 Android (24/7.0; 640dpi; 1440x2560; samsung; SM-G930F; herolte; samsungexynos8890; en_US; 138226743)',
        'Origin': "https://www.instagram.com", 
        'Referer': "https://www.instagram.com/",
        'Access-Control-Allow-Origin': "*",
        'Content-Type': 'application/json',
      },
      body:JSON.stringify({username: username}),
    }).then(()=> console.log('sent'))
    }
  
  return (
    <creator>
      <NavBar />
      <div className='project' id='about'>
        <h2>Creator Form</h2>
                <p>Register, Create and Earn!!!</p>
                
            <Creator_form />


    </div>      
    <Footer />
    </creator>
  )
}

export default Creator