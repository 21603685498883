import React from 'react'
import { NavBar } from '../components/NavBar'
import { Footer } from '../components/Footer'
import {Brand_form}from '../components/Brand-Form'

function Brand() {
  return (
    <brand>
      <NavBar />
      <div className='project' id='about'>
        <h2>Brand   Form</h2>
                <p>Fill the form and get your products advertised!!</p>
                
            <Brand_form />


    </div>      
    <Footer />
    </brand>
  )
}

export default Brand