import React from 'react'
import { NavBar } from '../components/NavBar'
import { Footer } from '../components/Footer'
import Lottie from 'lottie-react'
import about from '../assets/img/about.png'
import TrackVisibility from 'react-on-screen';

function About() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: about,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div>
      <NavBar />
      <div className='about' >
      
              <div > 
              <img src={about} alt='about us'/>
                            </div>        
        <h2>About Us</h2>
                <p>Welcome to Elevance, where creativity meets opportunity! 

At Elevance, we believe in empowering both micro and macro creators by providing a platform to share their unique content and monetize their skills. Whether you're a budding influencer, a seasoned content creator, or a passionate storyteller, Elevance offers you the stage to showcase your talent and connect with your audience.

One of the key aspects of Elevance is our partnership with highly reputed brands. We bridge the gap between creators and brands, facilitating meaningful collaborations that benefit both parties. By working with us, creators get the chance to collaborate with brands they admire, expanding their reach and unlocking new opportunities.

Moreover, Elevance is committed to supporting brands in their organic marketing efforts. We understand the challenges of navigating the digital landscape, which is why we offer cost-effective solutions to boost brand visibility and engagement. Our tailored strategies and services ensure that brands can achieve their marketing goals without breaking the bank.

Join Elevance today and embark on a journey of creativity, collaboration, and growth. Together, let's elevate your content, amplify your brand, and unlock endless possibilities!</p>
    </div>
    <Footer />
    </div>
  )
}

export default About