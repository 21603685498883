import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/ele_logo.png";
import navIcon1 from "../assets/img/insta.svg";
import navIcon2 from "../assets/img/discord.svg";
import navIcon3 from "../assets/img/youtube.svg";
import Lottie from "lottie-react";
import animate from '../assets/img/animate_1.json'
export const Footer = () => {
  return (
    <footer className="footer">
      <div>
      <div className="visme_d" data-title="Contest Registration Form Template" data-url="https://forms.visme.co/formsPlayer/76n6ox0y-contest-registration-form-template" data-domain="forms" data-full-page="false" data-min-height="600px" data-form-id="46645"></div><script src="https://static-bundles.visme.co/forms/vismeforms-embed.js"></script>
      </div>
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
            <p>We're all about giving micro and macro creators a stage to shine and earn. Imagine working with top-notch brands and getting paid for your genius!

Oh, and did we mention? We're wizards at organic marketing, and we do it all without burning a hole in your pocket.

Join Elevance, where the magic of content creation meets the charm of brand collaboration. Let's make some noise and turn heads together!</p>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a onClick={()=> window.open('https://www.instagram.com/elevance_club?igsh=MTE4dnIwejdodmg5aA==')}><img src={navIcon1} alt="Icon" /></a>
              <a onClick={()=> window.open('https://discord.com/invite/Cq4NCBJFc9')}><img src={navIcon2} alt="Icon" /></a>
              <a onClick={()=> window.open('https://youtube.com/@ElevanceClub?si=n8MgcX0YuGPvN6IC')}><img src={navIcon3} alt="Icon" /></a>
            </div>
            <p>Copyright 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
