import Lottie from "lottie-react";
import { Col } from "react-bootstrap";

export const CreatorCard = ({ title, description, lottie }) => {
  return (
    <Col size={10} sm={4} md={4}>
      <div className="creator-card">
        <Lottie animationData={lottie} loop />
        <h4>{title}</h4>
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
        </div>
      </div>
    </Col>
  )
}
