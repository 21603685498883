import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import About from './pages/About';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './pages/Main';
import Creator from './pages/Creator'
import Brand from './pages/Brand';
import { Footer } from './components/Footer';
function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/about' element={<About />} />
        <Route path='/creator' element={<Creator />} />
        <Route path='/brand' element={<Brand />} />
      </Routes>
    </Router>
    /*
    <div className="App">
      <NavBar />
      <Banner />
      <Skills />
      <About />
      <Lets />
      <Projects />
      <Contact />
      <Footer />
    </div> 
    */
  );
}

export default App;
