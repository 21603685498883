import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import collab from '../assets/img/collab.json'
import advert from '../assets/img/advert.json'
import influence from '../assets/img/influence.json'
import earn from '../assets/img/earn.json'
import register from '../assets/img/register.json'
import create from '../assets/img/create.json'
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { CreatorCard } from "./CreatorCard";

export const Lets = () => {
  const creatorRedirect = ()=>
  { window.location = "/#/Creator"; 
}
const brandRedirect = ()=>
  { window.location = "/#/Brand"; 
}

  const cards = [
    {
      title: "Register",
      description: "Design & Development",
      lottie: register,
    },
    {
      title: "Create",
      description: "Design & Development",
      lottie: create,
    },
    {
      title: "Earn",
      description: "Design & Development",
      lottie: earn,
    },
  ];
  const cards_brand = [
    {
      title: "Collaborate",
      description: "Design & Development",
      lottie: collab,
    },
    {
      title: "Advertise",
      description: "Design & Development",
      lottie: advert,
    },
    {
      title: "Influence",
      description: "Design & Development",
      lottie: influence,
    },
  ];

  return (
    <section className="project" id="lets">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Lets Get Started!</h2>
                <p>Planning to earn or finding ways to advertise? We're here for you. Choose the best option that suits you!</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="let-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Creator</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Brand</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      {
                      
                        <div>
                          <Row>
                        {
                          cards.map((project, index) => {
                            return (
                              <CreatorCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                      <Row>
                      <button class="button-64" onClick={creatorRedirect}><span class="text">What are you waiting for? <br />Click here to register now!!</span></button> 
                    </Row>
                        </div>

                      }
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      {
                      
                      <div>
                        <Row>
                      {
                        cards_brand.map((project, index) => {
                          return (
                            <CreatorCard
                              key={index}
                              {...project}
                              />
                          )
                        })
                      }
                    </Row>
                    <Row>
                      <button class="button-64" onClick={brandRedirect}><span class="text">What are you waiting for? <br />Click here to register now!!</span></button>
                    </Row>
                      </div>

                    }
                    </Tab.Pane>
                    
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="bg"></img>
    </section>
  )
}
